import { TCategory } from '@/shared/apis/categories/types.categories';
import * as React from 'react';

export const API_ENDPOINT =
  process.env.NEXT_PUBLIC_API_URL || 'https://api.dev.inkhub.com/api';

export const filterCategories = (
  categories: TCategory[],
  companyType: string[],
): TCategory[] => {
  if (companyType.length === 0) {
    return categories;
  }

  return categories.filter(category =>
    companyType.includes(category.category_type),
  );
};
export const getHighlightedText = (
  text: string,
  higlight: string,
): JSX.Element[] => {
  const parts = text.split(new RegExp(`(${higlight})`, 'gi'));
  return parts.map((part, index) => (
    <React.Fragment key={index}>
      {part.toLowerCase() === higlight.toLowerCase() ? (
        <span>{part}</span>
      ) : (
        part
      )}
    </React.Fragment>
  ));
};

export function removeItemAll(arr: string[], value: string) {
  let i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

export function capitalizeFirstLetter(string: string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

export function replaceMultipleHyphensAndSpaces(input: string): string {
  const replacedSpaces = input.trim().replace(/\s+/g, ' ');

  const replacedHyphens = replacedSpaces.replace(/-+/g, '-');

  return replacedHyphens.trim();
}

export const formatNumberWithCommas = (value: string): string => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
