'use client';
import React from 'react';
import { DM_Sans } from 'next/font/google';
import { createTheme } from '@mui/material/styles';
import { colors } from './palette';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1XL: React.CSSProperties;
    ROTD_Heading: React.CSSProperties;
    ParagraphL: React.CSSProperties;
    Tab: React.CSSProperties;
    TagText: React.CSSProperties;
    BigTab: React.CSSProperties;
    caption0: React.CSSProperties;
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
    caption3: React.CSSProperties;
    caption4: React.CSSProperties;
    caption5: React.CSSProperties;
    caption6: React.CSSProperties;
    customSubtitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1XL?: React.CSSProperties;
    ROTD_Heading?: React.CSSProperties;
    ParagraphL?: React.CSSProperties;
    Tab?: React.CSSProperties;
    TagText?: React.CSSProperties;
    BigTab?: React.CSSProperties;
    caption0?: React.CSSProperties;
    caption1?: React.CSSProperties;
    caption2?: React.CSSProperties;
    caption3?: React.CSSProperties;
    caption4?: React.CSSProperties;
    caption5?: React.CSSProperties;
    caption6?: React.CSSProperties;
    customSubtitle?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1XL: true;
    ROTD_Heading: true;
    ParagraphL: true;
    Tab: true;
    TagText: true;
    BigTab: true;
    caption0: true;
    caption1: true;
    caption2: true;
    caption3: true;
    caption4: true;
    caption5: true;
    caption6: true;
    customSubtitle: true;
  }
}

const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 744,
      md: 1025,
      lg: 1200,
      xl: 1550,
    },
  },
});

const dmSans = DM_Sans({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  preload: true,
  display: 'swap',
});

const theme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: colors.blue,
      light: colors.blue10,
      dark: colors.blue30,
    },
    background: {
      default: colors.black100,
    },
    info: {
      main: colors.white,
    },
  },
  typography: {
    h1XL: {
      fontSize: '72px',
      [baseTheme.breakpoints.between('xs', 'sm')]: {
        fontSize: '40px',
      },
      [baseTheme.breakpoints.between('sm', 'md')]: {
        fontSize: '42px',
      },
      [baseTheme.breakpoints.between('md', 'lg')]: {
        fontSize: '45px',
      },
      [baseTheme.breakpoints.between('lg', 'xl')]: {
        fontSize: '48px',
      },
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '100%',
      letterSpacing: '-3.6px',
    },
    ROTD_Heading: {
      fontSize: '55px',
      [baseTheme.breakpoints.between('xs', 'sm')]: {
        fontSize: '24px',
      },
      [baseTheme.breakpoints.between('sm', 'md')]: {
        fontSize: '26px',
      },
      [baseTheme.breakpoints.between('md', 'lg')]: {
        fontSize: '28px',
      },
      [baseTheme.breakpoints.between('lg', 'xl')]: {
        fontSize: '30px',
      },
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '105%',
      letterSpacing: '-0.75px',
    },
    ParagraphL: {
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '25.2px',
      letterSpacing: '-0.36px',
      [baseTheme.breakpoints.down('xl')]: {
        fontSize: '16px',
      },
    },
    Tab: {
      fontSize: '18px',
      [baseTheme.breakpoints.between('xs', 'sm')]: {
        fontSize: '14px',
      },
      [baseTheme.breakpoints.between('sm', 'md')]: {
        fontSize: '15px',
      },
      [baseTheme.breakpoints.between('md', 'lg')]: {
        fontSize: '16px',
      },
      [baseTheme.breakpoints.between('lg', 'xl')]: {
        fontSize: '16px',
      },
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '105%',
      letterSpacing: '-0.72px',
    },
    TagText: {
      fontSize: '16px',
      [baseTheme.breakpoints.between('xs', 'sm')]: {
        fontSize: '12px',
      },
      [baseTheme.breakpoints.between('sm', 'md')]: {
        fontSize: '14px',
      },
      [baseTheme.breakpoints.between('md', 'lg')]: {
        fontSize: '15px',
      },
      [baseTheme.breakpoints.between('lg', 'xl')]: {
        fontSize: '16px',
      },
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '-0.64px',
    },
    BigTab: {
      fontSize: '26px',
      [baseTheme.breakpoints.between('xs', 'sm')]: {
        fontSize: '14px',
      },
      [baseTheme.breakpoints.between('sm', 'md')]: {
        fontSize: '16px',
      },
      [baseTheme.breakpoints.between('md', 'lg')]: {
        fontSize: '18px',
      },
      [baseTheme.breakpoints.between('lg', 'xl')]: {
        fontSize: '20px',
      },
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '105%',
      letterSpacing: '-0.72px',
    },
    caption0: {
      fontSize: '14px',
      [baseTheme.breakpoints.between('xs', 'sm')]: {
        fontSize: '9px',
      },
      // ToDo: Ask if we need average sises for it
      // [baseTheme.breakpoints.between("sm", "md")]: {
      //   fontSize: "10.25px"
      // },
      // [baseTheme.breakpoints.between("md", "lg")]: {
      //   fontSize: "11.5px"
      // },
      // [baseTheme.breakpoints.between("lg", "xl")]: {
      //   fontSize: "12.75px"
      // },
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '130%',
      letterSpacing: '-0.28px',
    },
    caption1: {
      fontSize: '14px',
      [baseTheme.breakpoints.between('xs', 'sm')]: {
        fontSize: '11px',
      },
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: '-0.28px',
    },
    caption2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '100%',
      letterSpacing: '-0.28px',
    },
    caption3: {
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '33px',
      letterSpacing: '-0.02em',
    },
    caption4: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '100%',
      letterSpacing: '-0.04em',
    },
    caption5: {
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '110%',
      letterSpacing: '0.02em',
    },
    caption6: {
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '-0.03em',
    },
    customSubtitle: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '120%',
      letterSpacing: '-1.04px',
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: '26px',
      },
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
          marginLeft: '8px',
          width: '4px',
          borderRadius: '8px',
          backgroundColor: colors.black300,
        },
        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
          width: '4px',
          borderRadius: '8px',
          backgroundColor: colors.black500,
        },
        '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
          {
            backgroundColor: colors.black500,
          },
        '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
          {
            backgroundColor: colors.black500,
          },
        '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
          {
            backgroundColor: colors.black500,
          },
        '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
          backgroundColor: colors.black500,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          [baseTheme.breakpoints.up('xl')]: {
            maxWidth: '1420px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: dmSans.style.fontFamily,
          // color: 'rgba(44, 57, 87, 1)',
          borderColor: 'rgba(155, 168, 183, 1)',
          '::placeholder': {
            color: 'rgba(85, 98, 130, 1)',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: dmSans.style.fontFamily,
          color: colors.black,
        },
        h1: {
          fontSize: '64px',
          fontWeight: 700,
          lineHeight: '100%',
          letterSpacing: '-0.02em',
          [baseTheme.breakpoints.between('xs', 'sm')]: {
            fontSize: '32px',
          },
          [baseTheme.breakpoints.between('sm', 'md')]: {
            fontSize: '34px',
          },
          [baseTheme.breakpoints.between('md', 'lg')]: {
            fontSize: '37px',
          },
          [baseTheme.breakpoints.between('lg', 'xl')]: {
            fontSize: '40px',
          },
        },
        h2: {
          fontSize: '40px',
          [baseTheme.breakpoints.between('xs', 'sm')]: {
            fontSize: '24px',
          },
          [baseTheme.breakpoints.between('sm', 'md')]: {
            fontSize: '25px',
          },
          [baseTheme.breakpoints.between('md', 'lg')]: {
            fontSize: '28px',
          },
          [baseTheme.breakpoints.between('lg', 'xl')]: {
            fontSize: '30px',
          },
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '110%',
          letterSpacing: '-0.55px',
        },
        h3: {
          fontSize: '26px',
          [baseTheme.breakpoints.between('xs', 'sm')]: {
            fontSize: '22px',
          },
          [baseTheme.breakpoints.between('sm', 'md')]: {
            fontSize: '22.5px',
          },
          [baseTheme.breakpoints.between('md', 'lg')]: {
            fontSize: '23px',
          },
          [baseTheme.breakpoints.between('lg', 'xl')]: {
            fontSize: '24px',
          },
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '130%',
          letterSpacing: '-1.6px',
        },
        h4: {
          fontSize: '20px',
          [baseTheme.breakpoints.between('xs', 'sm')]: {
            fontSize: '16px',
          },
          [baseTheme.breakpoints.between('sm', 'md')]: {
            fontSize: '16.5px',
          },
          [baseTheme.breakpoints.between('md', 'lg')]: {
            fontSize: '17px',
          },
          [baseTheme.breakpoints.between('lg', 'xl')]: {
            fontSize: '18px',
          },
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '130%',
          letterSpacing: '-0.8px',
        },
        h5: {
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          letterSpacing: '-0.72px',
        },
        button: {
          fontSize: '14px',
          [baseTheme.breakpoints.up('sm')]: {
            fontSize: '16px',
          },
          [baseTheme.breakpoints.up('xl')]: {
            fontSize: '20px',
          },
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '100%',
          letterSpacing: '-0.8px',
        },
        subtitle1: {
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '140%',
          letterSpacing: '-0.02em',
          [baseTheme.breakpoints.up('sm')]: {
            fontSize: '18px',
          },
        },
        subtitle2: {
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '110%',
          letterSpacing: '-0.48px',
        },
        body1: {
          fontSize: '16px',
          [baseTheme.breakpoints.between('xs', 'sm')]: {
            fontSize: '12px',
          },
          [baseTheme.breakpoints.between('sm', 'md')]: {
            fontSize: '12.5px',
          },
          [baseTheme.breakpoints.between('md', 'lg')]: {
            fontSize: '13px',
          },
          [baseTheme.breakpoints.between('lg', 'xl')]: {
            fontSize: '14px',
          },
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '130%',
          letterSpacing: '-0.32px',
        },
        body2: {
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '100%',
          letterSpacing: '-0.64px',
        },
      },
    },
  },
});

export default theme;
